import css from './Scroll.module.css';

function Scroll() {
  return (
    <>
      <div className={css.container}>
        <div className={css.main}/>
      </div>
    </>
  );
}

export default Scroll;
