import CustomCursor from 'custom-cursor-react';
import { isMobile } from 'react-device-detect';
import './App.css';
import AuroraBackground from './components/AuroraBackground';
import Scroll from './components/Scroll/Scroll';
import Header from './sections/Header';
import Links from './sections/Links';

function App() {
  return (
    <>
      { !isMobile && <CustomCursor
        targets={['a']}
        customClass="Cursor"
        dimensions={50}
        fill="rgba(0, 0, 0, 0)"
        strokeColor={'#fff'}
        strokeWidth={5}
        smoothness={{
          movement: 1,
          scale: 0.1,
          opacity: 0.2,
        }}
        targetOpacity={0.1}
        targetScale={5}
        opacity={1}
      />}
      <div className="App">
        <AuroraBackground/>
        <div className="Content">
          <Header/>
          <Links/>
          <Scroll/>
        </div>
      </div>
    </>
  );
}

export default App;
