interface Props extends React.HTMLAttributes<HTMLDivElement>{
  image: string
  rotation?: string
  link?: string
  zoom?: string
  size?: number
}

function Panel({ image, link, rotation, zoom = '85%', size = 1 }:Props) {
  const mainPanel = <div style={{
    width: `${150 * size}px`,
    height: `${150 * size}px`,
    borderRadius: '10%',
    boxShadow: '20px 20px 30px rgba(0, 0, 0, 0.53), -20px -20px 30px rgba(0, 0, 0, 0.53)',
    backdropFilter: 'blur(5px) brightness(90%)',
    WebkitBackdropFilter: 'blur(5px) brightness(90%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
    rotate: rotation
  }}>
    <img style={{
      filter: 'invert(100%)'
    }} src={image} width={zoom} alt="" />
  </div>;

  return (
    <>
      {(link)
        ? <a href={link} target={'_blank'}>
          {mainPanel}
        </a>
        : mainPanel
      }
    </>
  );
}

export default Panel;
