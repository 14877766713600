import { MouseParallaxChild, MouseParallaxContainer } from 'react-parallax-mouse';
import { config } from 'react-spring';
import discord from '../assets/discord.svg';
import email from '../assets/email.svg';
import github from '../assets/github.svg';
import nodejs from '../assets/nodejs.svg';
import npmjs from '../assets/npmjs.svg';
import react from '../assets/react.svg';
import stackoverflow from '../assets/stackoverflow.svg';
import twitter from '../assets/twitter.svg';
import typescript from '../assets/typescript.svg';
import Panel from '../components/Panel';

function Links() {
  return (
    <>
      <div className="Links">
        <MouseParallaxContainer
          globalFactorX={0.08}
          globalFactorY={0.08}
          containerStyle={{
            height: '100%',
            width: '100%',
          }}
          useWindowMouseEvents
          resetOnLeave
          springConfig={config.slow}
        >

          {/* Skills */}

          {/* React */}
          <MouseParallaxChild factorX={0.3} factorY={0.3} style={{
            position: 'absolute',
            bottom: '10%',
            right: '25%',
            filter: 'brightness(80%)'
          }}>
            <Panel
              image={react}
              rotation={'-13deg'}
              size={0.7}
            />
          </MouseParallaxChild>

          {/* NodeJS */}
          <MouseParallaxChild factorX={0.4} factorY={0.4} style={{
            position: 'absolute',
            bottom: '13%',
            left: '15%',
            filter: 'brightness(80%)'

          }}>
            <Panel
              image={nodejs}
              rotation={'-20deg'}
              size={0.7}
            />
          </MouseParallaxChild>

          {/* Typescript */}
          <MouseParallaxChild factorX={0.5} factorY={0.5} style={{
            position: 'absolute',
            bottom: '40%',
            right: '10%',
            filter: 'brightness(80%)'
          }}>
            <Panel
              image={typescript}
              rotation={'-25deg'}
              size={0.7}
            />
          </MouseParallaxChild>


          {/* Links */}

          {/* Github */}
          <MouseParallaxChild factorX={1} factorY={1} style={{
            position: 'absolute',
            top: '20%',
            left: '8%',
          }}>
            <Panel
              image={github}
              rotation={'-30deg'}
              link={'https://github.com/choozn'}
              zoom={'105%'}
            />
          </MouseParallaxChild>

          {/* NPM */}
          <MouseParallaxChild factorX={1.3} factorY={1.3} style={{
            position: 'absolute',
            bottom: '35%',
            left: '8%',
          }}>
            <Panel
              image={npmjs}
              zoom="75%"
              rotation={'25deg'}
              link={'https://www.npmjs.com/~choozn'}
            />
          </MouseParallaxChild>

          {/* Stackoverflow */}
          <MouseParallaxChild factorX={1.1} factorY={1.1} style={{
            position: 'absolute',
            bottom: '20%',
            left: '30%',
          }}>
            <Panel
              image={stackoverflow}
              zoom="75%"
              rotation={'-18deg'}
              link={'https://stackoverflow.com/users/14097941/choozn'}
            />
          </MouseParallaxChild>

          {/* Discord */}
          <MouseParallaxChild factorX={1.2} factorY={1.2} style={{
            position: 'absolute',
            bottom: '180px',
            right: '38%',
          }}>
            <Panel
              image={discord}
              zoom="75%"
              rotation={'20deg'}
              link={'https://discord.gg/sjyGndhqCt'}
            />
          </MouseParallaxChild>

          {/* Email */}
          <MouseParallaxChild factorX={1.3} factorY={1.3} style={{
            position: 'absolute',
            bottom: '150px',
            right: '100px',
          }}>
            <Panel
              image={email}
              zoom="75%"
              rotation={'-35deg'}
              link={'mailto:hey@choozn.dev'}
            />
          </MouseParallaxChild>

          {/* Twitter */}
          <MouseParallaxChild factorX={1.1} factorY={1.1} style={{
            position: 'absolute',
            top: '15%',
            right: '8%',
          }}>
            <Panel
              image={twitter}
              zoom="75%"
              rotation={'20deg'}
              link={'https://twitter.com/choozn_dev'}
            />
          </MouseParallaxChild>
        </MouseParallaxContainer>
      </div>
    </>
  );
}

export default Links;
