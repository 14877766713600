
import background from '../assets/background.jpg';

function AuroraBackground() {
  return (
    <>
      <div
        style={{
          background: `url("${background}") 0% 0% / 100% 100% no-repeat`,
          pointerEvents: 'none',
          userSelect: 'none',
          position: 'absolute',
          width: '100%',
          height: '100%',
          filter: 'blur(90px) brightness(90%)'
        }}/>
    </>
  );
}

export default AuroraBackground;
