function Header() {
  return (
    <>
      <div className="Header">
        <h1>Hey! I'm choozn</h1>
        <div className="About">
          <p>I like to code stuff. I love <b>Typescript</b>, and I am a big advocate of clean code.
              You might know me from my previous projects, such as <b>PopupDB</b>.
          </p>
          <p>
              If you want to contact me, feel free to do so by sending me an email at <a href="mailto:hey@choozn.dev">hey@choozn.dev</a>.
          </p>
        </div>
      </div>
    </>
  );
}

export default Header;
